import React from 'react'
import LineTaxiIcon from 'components/Luxkit/Icons/line/LineTaxiIcon'
import OrderUpsellTileWithTracking from 'components/Common/OrderUpsellTileWithTracking'

interface Props {
  transferUpsellData: App.AirportTransferUpsell
  variant?: 'compact' | 'default';
  onDismiss?: () => void;
}

function AirportTransferUpsellTile(props: Props) {
  const {
    transferUpsellData,
    variant = 'default',
    onDismiss,
  } = props

  const {
    price,
    experienceSearchUrl,
  } = transferUpsellData

  return <OrderUpsellTileWithTracking
    title="Airport transfers"
    subTitle="Free cancellation available"
    description="Reliable and private airport transfers."
    price={price}
    priceUnit="adult"
    IconComponent={LineTaxiIcon}
    to={experienceSearchUrl}
    analyticsSubject="transfer"
    variant={variant}
    onDismissClick={onDismiss}
  />
}

export default AirportTransferUpsellTile
