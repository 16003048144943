import { useMemo, useState, useCallback, useContext } from 'react'
import { getItineraryCountries } from 'checkout/lib/utils/cruises/itinerary'
import useOffers from 'hooks/Offers/useOffers'
import { useAppSelector } from 'hooks/reduxHooks'
import { max, min, nonNullable } from 'lib/array/arrayUtils'
import { isCoverGeniusEnabled, isCoverGeniusEndDateValid } from 'lib/insurance/insuranceHelpers'
import { countOccupants, countTravellersAsOccupants } from 'lib/offer/occupancyUtils'
import { isLEHotel, isCruiseOffer } from 'lib/offer/offerTypes'
import { getUpsellDismissalStorageKey } from 'lib/order/upsellUtils'
import { checkCanViewLuxPlusBenefits, isEnabledLuxPlusDiscountedInsurance } from 'luxPlus/selectors/featureToggle'
import {
  get as getLocalStorage,
  set as setLocalStorage,
} from 'lib/storage/isomorphicLocalStorage'
import { getDestinationCountriesFromOrder, getInsuranceDatesFromOrder } from 'lib/order/orderInsuranceUtils'
import useInsuranceQuote from 'hooks/Insurance/useInsuranceQuote'
import useInsuranceProducts from 'hooks/Insurance/useInsuranceProducts'
import GeoContext from 'contexts/geoContext'

interface InsuranceUpsellData {
  offerId?: string;
  occupants?: App.Occupants;
  destinationCountries?: Array<string>;
  destinationCountryCode?: string | null;
  provider?: string;
  reservationType?: string;
  startDate: string;
  endDate: string;
  status?: App.OrderItemStatus;
  type: string;
}

function useInsuranceUpsell(
  order: App.Order,
): {
  insuranceUpsellEnabled: true
  insuranceUpsell: App.InsuranceUpsell
  handleInsuranceUpsellDismiss: () => void
  insuranceDataLoading: boolean
} | {
  insuranceUpsellEnabled: false
  insuranceUpsell: undefined
  handleInsuranceUpsellDismiss: undefined
  insuranceDataLoading: boolean
} {
  const [dismissed, setDismissed] = useState<boolean>(!!getLocalStorage(getUpsellDismissalStorageKey('insurance', order.id)))

  const handleInsuranceUpsellDismiss = useCallback(() => {
    setLocalStorage(getUpsellDismissalStorageKey('insurance', order.id), true)
    setDismissed(true)
  }, [order.id])

  const fetchingLuxPlusSubscription = useAppSelector(state => state.auth.account.luxPlus.member.subscription.fetching)
  const ableToViewLuxPlusBenefits = useAppSelector(checkCanViewLuxPlusBenefits)
  const luxPlusDiscountedInsuranceEnabled = useAppSelector(isEnabledLuxPlusDiscountedInsurance)
  const offerIds = useMemo(() => [
    ...order.items.map((i) => i.offer.id),
    ...order.cruiseItems.map((i) => i.cruiseOfferId),
  ], [order.cruiseItems, order.items])

  const [offers, fetching] = useOffers(offerIds)

  const dates = useMemo(() => getInsuranceDatesFromOrder(order), [order])
  const datesAreValid = dates?.endDate ? isCoverGeniusEndDateValid(dates.endDate) : false
  const cruiseOffers = useAppSelector(state => state.cruise.cruiseOffers)
  const destCountriesFromOrder = useMemo(() => getDestinationCountriesFromOrder(order, cruiseOffers), [cruiseOffers, order])
  const coverGeniusIsEnabled = useAppSelector(state => isCoverGeniusEnabled(state.geo.currentRegionCode))
  const isInsuranceSupported = !!(dates && destCountriesFromOrder.length) && coverGeniusIsEnabled
  const orderHasInsurance = order.hasInsurance

  const [earliestItem, latestItem] = useMemo(() => {
    const possibleUpsells: Array<InsuranceUpsellData> = [
      ...nonNullable(order.items.map(item => {
        const hotelOffer = offers.find(offer => offer.id === item.offer.id)
        if (isLEHotel(hotelOffer) && item.offer.property && item.reservation) {
          return {
            offerId: item.offer.id,
            occupants: {
              adults: item.reservation.adults,
              children: item.reservation.children,
              infants: item.reservation.infants,
            },
            destinationCountries: nonNullable([hotelOffer.property.geoData.country]),
            destinationCountryCode: hotelOffer.property.geoData.countryCode,
            reservationType: item.reservationType,
            startDate: item.reservation.startDate,
            endDate: item.reservation.endDate,
            status: item.status,
            type: 'hotel',
          }
        }
      })),
      ...nonNullable(order.bedbankItems.map(item => {
        const occupancies: Array<App.Occupants> = item.rooms.map(room => room.occupancy)
        const occupants = countOccupants(occupancies)

        return {
          offerId: item.offer.id,
          occupants: {
            adults: occupants?.adults,
            children: occupants?.children,
            infants: occupants?.infants,
          },
          destinationCountries: [item.offer.property.address.countryName],
          reservationType: item?.reservationId,
          startDate: item.checkIn.format('YYYY-MM-DD'),
          endDate: item.checkOut.format('YYYY-MM-DD'),
          status: item.status,
          type: 'bedbank_hotel',
        }
      })),
      ...nonNullable(order.tourItems.map(item => {
        const travellers = countTravellersAsOccupants(item.tour.travellers)

        return {
          offerId: item.tourId,
          occupants: {
            adults: travellers.adults,
            children: travellers.children,
            infants: travellers.infants,
          },
          destinationCountries: item.tour.countriesVisited,
          reservationType: item.reservationId,
          startDate: item.tour.startDate,
          endDate: item.tour.endDate,
          status: item.status,
          type: 'tour',
        }
      })),
      ...nonNullable(order.cruiseItems.map(item => {
        const cruiseOffer = offers.find(offer => offer.id === item.cruiseOfferId)

        const passengers = countTravellersAsOccupants(item.passengers)
        if (isCruiseOffer(cruiseOffer)) {
          return {
            offerId: item.cruiseOfferId,
            occupants: {
              adults: passengers.adults,
              children: passengers.children,
              infants: passengers.infants,
            },
            destinationCountries: getItineraryCountries(cruiseOffer),
            reservationType: item.reservationId,
            startDate: item.departureDate,
            endDate: item.arrivalDate,
            status: item.status,
            type: 'cruise',
          }
        }
      })),
    ].filter(item => item.status === 'completed')

    const earliest = min(possibleUpsells, item => new Date(item.startDate))
    const latest = max(possibleUpsells, item => new Date(item.endDate))

    return [earliest, latest]
  }, [order, offers])

  const { currentRegionName } = useContext(GeoContext)
  const isDomestic = useMemo(() => !!earliestItem?.destinationCountries?.every(country => country == currentRegionName), [currentRegionName, earliestItem?.destinationCountries])
  const isCruise = earliestItem?.type === 'cruise'
  const [products] = useInsuranceProducts('travel-insurance', {
    isCruise,
    isDomestic,
  })
  const [quote, fetchingQuotes] = useInsuranceQuote(products[0]?.id,
    {
      startDate: earliestItem?.startDate,
      endDate: latestItem?.endDate,
      destinationCountries: earliestItem?.destinationCountries,
      coverAmount: order?.total,
      travellers: earliestItem?.occupants,
      isCruise,
      type: 'travel-insurance',
    })

  const [cheapestQuotePrice, cheapestQuoteLuxPlusPrice] = useMemo(() => {
    let cheapestQuotePrice = 0
    let cheapestQuoteLuxPlusPrice = 0

    if (quote && earliestItem && latestItem) {
      const policyQuotes = quote.policyQuotes
      cheapestQuotePrice = min(policyQuotes.map(item => item.pricePerPerson)) ?? 0

      if (ableToViewLuxPlusBenefits && luxPlusDiscountedInsuranceEnabled) {
        cheapestQuoteLuxPlusPrice = min(policyQuotes.map(item => item.luxPlusPricePerPerson)) ?? 0
      }
    }
    return [cheapestQuotePrice, cheapestQuoteLuxPlusPrice]
  }, [ableToViewLuxPlusBenefits, earliestItem, latestItem, luxPlusDiscountedInsuranceEnabled, quote])

  const enabled = !dismissed &&
  isInsuranceSupported &&
  !orderHasInsurance &&
  datesAreValid

  const insuranceUpsell = useMemo<App.InsuranceUpsell>(() => {
    return {
      checkCanViewLuxPlusBenefits: ableToViewLuxPlusBenefits,
      isEnabledLuxPlusDiscountedInsurance: luxPlusDiscountedInsuranceEnabled,
      fetchingLuxPlusSubscription,
      fetchingQuotes,
      cheapestQuotePrice,
      cheapestQuoteLuxPlusPrice,
      orderId: order.id,
    }
  }, [
    ableToViewLuxPlusBenefits,
    cheapestQuoteLuxPlusPrice,
    cheapestQuotePrice,
    fetchingLuxPlusSubscription,
    fetchingQuotes,
    luxPlusDiscountedInsuranceEnabled,
    order.id,
  ])

  if (enabled) {
    return {
      insuranceUpsellEnabled: true,
      insuranceUpsell,
      handleInsuranceUpsellDismiss,
      insuranceDataLoading: fetching,
    }
  } else {
    return {
      insuranceUpsellEnabled: false,
      insuranceUpsell: undefined,
      handleInsuranceUpsellDismiss: undefined,
      insuranceDataLoading: fetching,
    }
  }
}

export default useInsuranceUpsell
