import React, { ComponentProps } from 'react'
import Heading from '../Typography/Heading'
import styled from 'styled-components'
import { rem } from 'polished'
import { _TextBlockIcon, TextBlockProps, _TextBlockStyle } from './_TextBlock'
import cn from 'clsx'

const TextBlock = styled(Heading)`
  ${_TextBlockStyle}

  &.variant-heading1,
  &.variant-heading1alt,
  &.variant-heading2,
  &.variant-heading2alt,
  &.variant-heading3,
  &.variant-heading3alt {
    --text-block-gap: ${rem(16)};
  }

  &.variant-heading4 {
    --text-block-gap: ${rem(8)};
  }

  &.variant-heading5 {
    --text-block-gap: ${rem(4)};
  }

  &.variant-heading6 {
    --text-block-gap: ${rem(8)};
  }
`

interface Props extends Omit<ComponentProps<typeof Heading>, 'as' | 'forwardedAs'>, TextBlockProps {
}

function HeadingTextBlock(props: Props) {
  const {
    children,
    endIcon = null,
    startIcon = null,
    fit,
    align,
    tabletAlign,
    variant,
    className,
    format,
    lineClamp,
    wrap,
    ...rest
  } = props

  return <TextBlock
    {...rest}
    variant={variant}
    className={cn(
      className,
      fit ? `fit-${fit}` : undefined,
      align ? `align-${align}` : undefined,
      tabletAlign ? `align-on-tablet-${tabletAlign}` : undefined,
    )}
  >
    {!!startIcon && <_TextBlockIcon>{startIcon}</_TextBlockIcon>}
    <Heading
      variant={variant}
      format={format}
      lineClamp={lineClamp}
      wrap={wrap}
      align={align}
      tabletAlign={tabletAlign}
      colour={rest.colour}
    >
      {children}
    </Heading>
    {!!endIcon && <_TextBlockIcon>{endIcon}</_TextBlockIcon>}
  </TextBlock>
}

export default HeadingTextBlock
