import useAirportTransferUpsell from './useAirportTransferUpsell'
import useCarHireUpsell from './useCarHireUpsell'
import useFlightUpsell from './useFlightUpsell'
import useInsuranceUpsell from './useInsuranceUpsell'
import useTourUpsell from './useTourUpsell'

type AllUpsellResult = Pick<ReturnType<typeof useAllUpsells>, 'tour' | 'insurance' | 'airportTransfer' | 'carHire' | 'flight'>

function anyUpsellsEnabed(upsells: AllUpsellResult): boolean {
  const {
    tour: { tourUpsellEnabled },
    insurance: { insuranceUpsellEnabled },
    flight: { flightUpsellEnabled },
    airportTransfer: { airportTransferUpsellEnabled },
    carHire: { carHireUpsellEnabled },
  } = upsells
  return tourUpsellEnabled || insuranceUpsellEnabled || flightUpsellEnabled || airportTransferUpsellEnabled || carHireUpsellEnabled
}

function anyUpsellsLoading(upsells: AllUpsellResult): boolean {
  const {
    tour: { tourDataLoading },
    insurance: { insuranceDataLoading },
    flight: { flightDataLoading },
    airportTransfer: { airportTransferDataLoading },
    carHire: { carHireDataLoading },
  } = upsells
  return tourDataLoading || insuranceDataLoading || flightDataLoading || airportTransferDataLoading || carHireDataLoading
}

function useAllUpsells(order: App.Order) {
  const tour = useTourUpsell(order)
  const insurance = useInsuranceUpsell(order)
  const flight = useFlightUpsell(order)
  const airportTransfer = useAirportTransferUpsell(order)
  const carHire = useCarHireUpsell(order)

  const upsells = {
    tour,
    insurance,
    flight,
    airportTransfer,
    carHire,
  }

  const anyUpsellEnabled = anyUpsellsEnabed(upsells)
  const anyUpsellLoading = anyUpsellsLoading(upsells)
  return {
    ...upsells,
    anyUpsellEnabled,
    anyUpsellLoading,
  }
}

export default useAllUpsells
