import { usePlaceByCoords } from 'hooks/usePlace'
import { replaceExperienceQueryString } from 'lib/experiences/experienceUtils'

interface ExperienceSearchParams {
  latLong?: { latitude?: number, longitude?: number };
  place?: App.Place;
  categoryIds?: Array<number>;
  placeLevels?: Array<App.PlaceType>;
  isMapUrl?: boolean;
}

function useExperienceSearchUrl(props: ExperienceSearchParams): [string, boolean] {
  const { latLong, place, categoryIds, placeLevels, isMapUrl } = props

  const [latLongPlace, fetching] = usePlaceByCoords(latLong?.latitude, latLong?.longitude, placeLevels)

  const params = {
    destinationId: place?.id ?? latLongPlace?.id,
    destinationName: place?.name ?? latLongPlace?.name,
    categories: categoryIds,
  }

  const query = replaceExperienceQueryString(params)

  if (isMapUrl) return [`/search/experiences/map?${query}`, fetching]

  return [`/search/experiences?${query}`, fetching]
}

export default useExperienceSearchUrl
