import { fetchInsuranceProducts } from 'actions/InsuranceActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { nonNullable } from 'lib/array/arrayUtils'
import { getInsuranceProductListKey } from 'lib/insurance/insuranceUtils'
import { useEffect, useMemo } from 'react'

interface InsuranceProductsOptions {
  isCruise?: boolean;
  isDomestic?: boolean;
  subscriptionId?: string;
  disabled?: boolean;
}

type ProductsReturn = [
  products: Array<App.InsuranceProduct>,
  fetching: boolean,
  error: any,
]

function useInsuranceProducts(
  type: App.InsuranceProductType,
  options: InsuranceProductsOptions = {},
): ProductsReturn {
  const dispatch = useAppDispatch()

  const key = getInsuranceProductListKey(type, options)
  const productList = useAppSelector(state => state.insurance.productLists[key])
  const allProducts = useAppSelector(state => state.insurance.products)
  const fetching = useAppSelector(state => !!state.insurance.fetchingProductLists[key])
  const error = useAppSelector(state => state.insurance.productListErrors[key])

  useEffect(() => {
    if (!options.disabled) {
      dispatch(fetchInsuranceProducts(type, {
        isCruise: options.isCruise,
        isDomestic: options.isDomestic,
        subscriptionId: options.subscriptionId,
      }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, options.disabled])

  const products = useMemo(() => {
    const ids = productList ?? []
    return nonNullable(ids.map(id => allProducts[id]))
  }, [allProducts, productList])

  return [
    products,
    fetching,
    error,
  ]
}

export default useInsuranceProducts
