import { useMemo } from 'react'
import { dateDifference } from 'lib/datetime/dateUtils'
import { TOUR_OPTIONAL_EXPERIENCE_BOOKING_DAYS_BEFORE_DEPARTURE } from 'constants/tours'

export default function usePurchasableTourOptionalExperience(
  tourItem: Tours.TourV2OrderItem,
  tourOffer: Tours.TourV2Offer | undefined,
  snapshot: Tours.TourV2OfferSnapshot,
): boolean {
  const hasOptionalExperiencesStillPurchasable = useMemo(() => {
    if (tourOffer && tourItem && snapshot) {
      const purchasableExperienceOptions = tourOffer.purchasableExperienceOptions ? Object.values(tourOffer.purchasableExperienceOptions) : []
      const isAvailableForBookingOptionalExperience = dateDifference(new Date(tourItem.tour.startDate)).days >= TOUR_OPTIONAL_EXPERIENCE_BOOKING_DAYS_BEFORE_DEPARTURE
      const hasOptionalExperiences = purchasableExperienceOptions.length > 0
      const alreadyBookedOptionalExperiences = purchasableExperienceOptions.every(purchasableOptionalExperience => {
        return (purchasableOptionalExperience.dayNumbers || []).every(dayNumber => {
          return !!snapshot.optionalExperiences?.find(
            orderExperienceItem => orderExperienceItem.dayNumber === dayNumber && orderExperienceItem.id === purchasableOptionalExperience.fkExperienceId,
          )
        })
      })
      return hasOptionalExperiences && isAvailableForBookingOptionalExperience && !alreadyBookedOptionalExperiences
    }
    return false
  }, [tourItem, tourOffer, snapshot])
  return hasOptionalExperiencesStillPurchasable
}
