import React from 'react'
import LineCarIcon from 'components/Luxkit/Icons/line/LineCarIcon'
import OrderUpsellTileWithTracking from 'components/Common/OrderUpsellTileWithTracking'
interface Props {
  carHireUpsellData: App.CarHireUpsell;
  variant?: 'compact' | 'default';
  onDismiss?: () => void;
}

function CarHireUpsellTile(props: Props) {
  const {
    carHireUpsellData,
    variant = 'default',
    onDismiss,
  } = props

  const {
    carHireSearchLink,
  } = carHireUpsellData

  return <OrderUpsellTileWithTracking
    title="Hire a car"
    subTitle="Free cancellation available"
    description="Trusted car suppliers and 24/7 support."
    IconComponent={LineCarIcon}
    to={carHireSearchLink}
    analyticsSubject="car_hire"
    variant={variant}
    onDismissClick={onDismiss}
  />
}

export default CarHireUpsellTile
