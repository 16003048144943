import { max, min, nonNullable } from 'lib/array/arrayUtils'
import { isBedbankOffer, isLEOffer } from 'lib/offer/offerTypes'
import moment, { Moment } from 'moment'
import qs from 'qs'

export interface FlightSearchLinkParams{
  originAirportCode: string;
  originAirportName: string | undefined;
  destinationAirportCode: string;
  destinationAirportName: string | undefined;
  departDate: string;
  returnDate: string;
  adults: number;
  children?: number;
  infants?: number;
  fareClass: string;
  fareType: string;
  maxArrivalTime?: string;
  minReturningDepartureTime?: string;
  source?: string;
  searchId?: number;
}

export function selectOrderItem(order: App.Order) {
  return order.items[0] // Working only for single hotels orders, we might change this in the future
}

export function selectBedbankOrderItem(order: App.Order): App.OrderBedbankItem {
  return order.bedbankItems[0]
}

export function getDateLabel(order: App.Order) {
  const item = selectOrderItem(order) // Working only for single hotels orders
  if (!item.reservation || item.reservationType === 'buy_now_book_later') return null
  return `${moment(item.reservation?.startDate).format('D MMM')} - ${moment(item.reservation?.endDate).format('D MMM YYYY')} · ${item.durationLabel}`
}

export function getOfferCountry(offer: App.BedbankOffer | App.HotelOffer) {
  if (isLEOffer(offer)) {
    return {
      countryName: offer.property.geoData.country,
      countryCode: offer.property.geoData.countryCode,
    }
  }
  if (isBedbankOffer(offer)) {
    return {
      countryName: offer.property.address.countryName,
      countryCode: offer.property.address.countryCode,
    }
  }
}

export function getUpsellFlightLink(startDate?: string, endDate?: string, occupants?: App.Occupants, originAirport?: App.Airport, destinationAirport?: App.Airport, flightsMaxArrivalTime?: string, flightsMinReturningDepartureTime?: string) {
  if (!originAirport || !destinationAirport || !startDate || !endDate || !occupants) return '/flights'

  const flightSearchParams:FlightSearchLinkParams = {
    originAirportCode: originAirport.code,
    originAirportName: originAirport.name,
    destinationAirportCode: destinationAirport.code,
    destinationAirportName: destinationAirport.name,
    departDate: startDate,
    returnDate: endDate,
    adults: occupants.adults,
    children: occupants.children,
    infants: occupants.infants,
    fareClass: 'economy',
    fareType: 'return',
    // This ensures the hotel checkin/out times are respected if set on the offer
    maxArrivalTime: flightsMaxArrivalTime,
    minReturningDepartureTime: flightsMinReturningDepartureTime,
    // This param ensures the depart/return dates are treated as hotel check in/out dates
    source: 'hotel',
    // searchId: moment().unix(),
  }

  return `/flights-search-results?${qs.stringify(flightSearchParams)}`
}

export function getOrderDateRange(order: App.Order) {
  const allOrderItemStartDates = nonNullable([
    ...order.items.filter(item => item.status !== 'cancelled' && item.reservation).map(item => new Date(item.reservation?.startDate!)),
    ...order.bedbankItems.filter(item => item.status !== 'cancelled').map(item => item.checkIn.toDate()),
    ...order.tourItems.filter(item => item.status !== 'cancelled').map(item => new Date(item.tour.startDate)),
    ...order.experienceItems.filter(item => item.status !== 'cancelled').map(item => new Date(item.date ?? item.bookByDate!)),
    ...order.flightItems.filter(item => item.status !== 'cancelled').map(item => new Date(item.departureDate!)),
    ...order.transferItems.filter(item => item.status !== 'cancelled').map(item => new Date(item.date)),
    ...order.carHireItems.filter(item => item.status !== 'cancelled' && item.status !== 'failed').map(item => new Date(item.reservation?.pickUp.date!)),
    ...order.cruiseItems.filter(item => item.status !== 'cancelled').map(item => new Date(item.departureDate)),
  ])

  const allOrderItemEndDates = nonNullable([
    ...order.items.filter(item => item.status !== 'cancelled' && item.reservation).map(item => new Date(item.reservation?.endDate!)),
    ...order.bedbankItems.filter(item => item.status !== 'cancelled').map(item => item.checkOut.toDate()),
    ...order.tourItems.filter(item => item.status !== 'cancelled').map(item => new Date(item.tour.endDate)),
    ...order.experienceItems.filter(item => item.status !== 'cancelled').map(item => new Date(item.date!)),
    ...order.flightItems.filter(item => item.status !== 'cancelled').map(item => new Date(item.arrivalDate!)),
    ...order.transferItems.filter(item => item.status !== 'cancelled').map(item => new Date(item.date)),
    ...order.carHireItems.filter(item => item.status !== 'cancelled' && item.status !== 'failed').map(item => new Date(item.reservation?.dropOff.date!)),
    ...order.cruiseItems.filter(item => item.status !== 'cancelled').map(item => new Date(item.arrivalDate)),
  ])

  const earliestDate = min(allOrderItemStartDates)
  const latestDate = max(allOrderItemEndDates)

  return {
    earliestDate,
    latestDate,
  }
}

export function getDaysToGoLabel(checkinDate?: string | Moment, duration?: number, verticalText: string = 'stay'): string {
  if (!checkinDate || !duration) return ''

  const checkIn = moment(checkinDate).startOf('day')
  const daysToGo = checkIn.diff(moment().startOf('day'), 'days')

  // Before stay
  if (daysToGo > 21) return `${Math.floor(daysToGo / 7)} weeks to go!`
  else if (daysToGo <= 21 && daysToGo > 1) return `${daysToGo} days to go!`
  else if (daysToGo === 1) return 'Check-in tomorrow'
  else if (daysToGo === 0) return 'Check-in today'
  // During stay
  else if (Math.abs(daysToGo) === (duration || 0) - 1) return 'Check-out tomorrow'
  else if (Math.abs(daysToGo) === (duration || 0)) return 'Check-out today'
  else if (Math.abs(daysToGo) < (duration || 0)) return `Enjoy your ${verticalText}`

  return ''
}
