import { fetchExperienceList } from 'actions/ExperienceActions'
import { EmptyExperienceList } from 'constants/experience'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { take, without } from 'lib/array/arrayUtils'
import { getExperienceListKey } from 'lib/experiences/experienceUtils'
import { useEffect, useMemo } from 'react'

interface Params {
  latitude?: number;
  longitude?: number;
  categoryCodes?: Array<number>;
  distance?: string;
  from?: string;
  to?: string;
  postPurchaseOnly?: boolean;
  offerId?: string;
  limit?: number;
  excludeIds?: Array<string>;
}

function useExperienceList(params: Params): [App.ApiCallState<{ ids: Array<string>}>, Array<App.ExperienceOffer>] {
  const {
    distance,
    limit = 8,
    from,
    to,
    postPurchaseOnly,
    categoryCodes,
    latitude,
    longitude,
    offerId,
    excludeIds = [],
  } = params

  const dispatch = useAppDispatch()

  const listKey = useMemo(() => {
    return getExperienceListKey({ latitude, longitude, distance, categoryCodes, from, to, postPurchaseOnly, offerId })
  }, [latitude, longitude, distance, categoryCodes, from, to, postPurchaseOnly, offerId])

  const experienceList = useAppSelector((state) => state.experience.experienceLists[listKey] ?? EmptyExperienceList)
  const experiences = useAppSelector((state) => state.experience.experiences)

  useEffect(() => {
    if (latitude && longitude) {
      dispatch(fetchExperienceList({ latitude, longitude, distance, categoryCodes, from, to, postPurchaseOnly, offerId }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryCodes, latitude, longitude, distance, from, to, postPurchaseOnly])

  const list = useMemo(() => {
    if (excludeIds.length === 0) {
      return experienceList
    }

    return {
      ...experienceList,
      ids: without(experienceList.ids, ...excludeIds),
    }
  }, [experienceList, excludeIds])

  const experienceItems = useMemo(() => {
    return take(list.ids, limit).map(id => experiences[id]).filter(Boolean)
  }, [experiences, list, limit])

  return [
    list,
    experienceItems,
  ]
}

export default useExperienceList
