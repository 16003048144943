import React from 'react'
import LineShieldCheckIcon from 'components/Luxkit/Icons/line/LineShieldCheckIcon'
import OrderUpsellTileWithTracking from 'components/Common/OrderUpsellTileWithTracking'

interface Props {
  insuranceUpsellData: App.InsuranceUpsell;
  variant?: 'compact' | 'default';
  onDismiss?: () => void;
}

function InsuranceUpsellTile(props: Props) {
  const {
    insuranceUpsellData,
    variant = 'default',
    onDismiss,
  } = props

  const {
    fetchingQuotes,
    fetchingLuxPlusSubscription,
    cheapestQuotePrice,
    cheapestQuoteLuxPlusPrice,
    isEnabledLuxPlusDiscountedInsurance,
    orderId,
  } = insuranceUpsellData

  return <OrderUpsellTileWithTracking
    title="Travel protection"
    description="Be covered for medical, cancellations and lost baggage."
    price={cheapestQuotePrice}
    luxPlusPrice={cheapestQuoteLuxPlusPrice}
    priceUnit="person"
    isPriceLoading={fetchingQuotes || (isEnabledLuxPlusDiscountedInsurance && fetchingLuxPlusSubscription)}
    currencyFormat="dynamicDollar"
    IconComponent={LineShieldCheckIcon}
    to={`/travel-protection/add?orderId=${orderId}`}
    analyticsSubject="insurance"
    className="insurance-upsell-tile"
    variant={variant}
    onDismissClick={onDismiss}
  />
}

export default InsuranceUpsellTile
