import React, { useCallback, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import * as Analytics from 'analytics/analytics'
import OrderUpsellTile from './OrderUpsellTile'
import CompactOrderUpsellTile from './CompactOrderUpsellTile'

type OrderUpsellTileProps = React.ComponentProps<typeof OrderUpsellTile>
interface Props {
  analyticsSubject: 'car_hire' | 'flight' | 'transfer' | 'insurance' | 'tour';
  analyticsCategory?: string;
  variant: 'compact'| 'default'
  onDismissClick?: () => void
}

function OrderUpsellTileWithTracking(props: Props & OrderUpsellTileProps) {
  const {
    analyticsSubject,
    analyticsCategory = 'upsell_tile',
    variant,
    onClick,
    onDismissClick,
    ...other
  } = props

  const [inViewRef, inView] = useInView({ triggerOnce: true })

  useEffect(() => {
    if (inView) {
      Analytics.trackClientEvent({
        subject: analyticsSubject,
        action: 'impression',
        category: analyticsCategory,
        type: 'nonInteraction',
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  const clickHandler = useCallback(() => {
    Analytics.trackClientEvent({
      subject: analyticsSubject,
      action: 'pressed',
      category: analyticsCategory,
      type: 'interaction',
    })
    if (onClick) {
      onClick()
    }
  }, [onClick, analyticsSubject, analyticsCategory])

  if (variant === 'compact') {
    return <CompactOrderUpsellTile
      {...other}
      tileType={analyticsSubject}
      ref={inViewRef}
      onClick={clickHandler}
      onDismissClick={onDismissClick}
    />
  }

  return <OrderUpsellTile
    {...other}
    ref={inViewRef}
    onClick={clickHandler}
    onDismissClick={onDismissClick}
  />
}
export default OrderUpsellTileWithTracking
