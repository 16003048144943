import { useMemo } from 'react'
import qs from 'qs'
import { getDefaultUserDriverCategory } from 'selectors/carHireSelectors'
import { useAppSelector } from 'hooks/reduxHooks'
import { usePlaceByCoords } from 'hooks/usePlace'

export interface CarHireSearchParams {
  pickUpTime: string,
  dropOffTime: string,
  age?: number,
  ageCategory: number,
  isPickUpAtAirport: boolean,
  pickUpSearchType: string,
  dropOffSearchType: string,
  pickUpName?: string,
  dropOffName?: string,
  pickUpId: string,
  dropOffId: string,
  pickUpDate?: string,
  dropOffDate?: string,
}

export function useCarHireSearchLink(startDate?: string, endDate?: string, latitude?: number, longitude?: number): [string, boolean] {
  const [ageCategory, age] = useAppSelector(getDefaultUserDriverCategory)
  const [place, fetching] = usePlaceByCoords(latitude, longitude)

  const carHireSearchLink = useMemo(() => {
    if (!place || !startDate || !endDate) return '/car-hire'

    const carHireSearchParams: CarHireSearchParams = {
      pickUpName: place.canonicalName,
      dropOffName: place.canonicalName,
      pickUpId: place.id,
      dropOffId: place.id,
      pickUpDate: startDate,
      dropOffDate: endDate,
      pickUpTime: '10:00',
      dropOffTime: '10:00',
      age,
      ageCategory,
      isPickUpAtAirport: false,
      pickUpSearchType: 'geo',
      dropOffSearchType: 'geo',
    }

    return `/search/car-hire?${qs.stringify(carHireSearchParams)}`
  }, [startDate, endDate, place, age, ageCategory])

  return [carHireSearchLink, fetching]
}
