import OrderUpsellTileWithTracking from 'components/Common/OrderUpsellTileWithTracking'
import LineHotAirBalloonIcon from 'components/Luxkit/Icons/line/LineHotAirBalloonIcon'
import React from 'react'

interface Props {
  tourUpsellData: App.TourUpsell
  variant?: 'compact' | 'default'
  onDismiss?: () => void
}

function TourOptionUpsellTile(props: Props) {
  const {
    tourUpsellData,
    variant = 'default',
    onDismiss,
  } = props

  const {
    onClick,
    price,
  } = tourUpsellData

  return <OrderUpsellTileWithTracking
    title="More things to do on your tour"
    description="Personalise your tour with optional experiences."
    price={price}
    priceUnit="person"
    currencyFormat="dynamicDollar"
    IconComponent={LineHotAirBalloonIcon}
    onClick={onClick}
    analyticsSubject="tour"
    variant={variant}
    onDismissClick={onDismiss}
  />
}

export default TourOptionUpsellTile
