import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { EXP_CATEGORY_AIRPORT_TRANSFER_ID } from 'constants/experience'
import GeoContext from 'contexts/geoContext'
import useExperienceList from 'hooks/Experiences/useExperienceList'
import useExperienceSearchUrl from 'hooks/Experiences/useExperienceSearchUrl'
import { max, min, nonNullable } from 'lib/array/arrayUtils'
import { useMemo, useContext, useCallback, useState } from 'react'
import { getUpsellDismissalStorageKey } from 'lib/order/upsellUtils'
import {
  get as getLocalStorage,
  set as setLocalStorage,
} from 'lib/storage/isomorphicLocalStorage'

interface AirportTransferUpsellData {
  coords: App.GeoCoords;
  from: string;
  to: string;
}

const placeLevels: Array<App.PlaceType> = ['multi_city_vicinity', 'province_state', 'city']
const airportCategory = [EXP_CATEGORY_AIRPORT_TRANSFER_ID]

function useAirportTransferUpsell(
  order: App.Order,
): {
  airportTransferUpsellEnabled: true
  airportTransferUpsell: App.AirportTransferUpsell
  handleAirportTransferDismiss: () => void
  airportTransferDataLoading: boolean
} | {
  airportTransferUpsellEnabled: false
  airportTransferUpsell: undefined
  handleAirportTransferDismiss: undefined
  airportTransferDataLoading: boolean
} {
  const [dismissed, setDismissed] = useState<boolean>(!!getLocalStorage(getUpsellDismissalStorageKey('transfer', order.id)))

  const handleAirportTransferDismiss = useCallback(() => {
    setLocalStorage(getUpsellDismissalStorageKey('transfer', order.id), true)
    setDismissed(true)
  }, [order.id])
  const hasTransfersAlready = useMemo(() => order.transferItems.filter(transfer => transfer.status === 'completed').length > 0, [order])
  const { geoStateName } = useContext(GeoContext)
  const isValidForTransfers = !!order.transferItems.length && !!(order.items.length || order.bedbankItems.length || order.flightItems.length || order.cruiseItems.length)

  const [earliestUpsell, latestUpsell] = useMemo(() => {
    if (hasTransfersAlready) {
      return []
    }

    /**
     * Transfers are either from an airport -> hotel or hotel -> airport
     * The only items with 'hotels' in them are items (with a property) and bedbank items
     * Thus, these are the only possible items eligable for transfers
     */
    const possibleUpsells: Array<AirportTransferUpsellData> = [
      ...order.items.filter(item => item.offer.property && item.reservation).map(item => ({
        coords: {
          latitude: item.offer.property?.latitude ?? 0,
          longitude: item.offer.property?.longitude ?? 0,
        },
        from: item.reservation?.startDate ?? '',
        to: item.reservation?.endDate ?? '',
        status: item.status,
        state: item.offer.property?.geoData?.administrativeAreaLevel1,
      })),
      ...order.bedbankItems.map(item => {
        return {
          coords: {
            latitude: item.offer.property.latitude,
            longitude: item.offer.property.longitude,
          },
          from: item.checkIn.format(ISO_DATE_FORMAT),
          to: item.checkOut.format(ISO_DATE_FORMAT),
          status: item.status,
          state: item.offer.property.address.stateProvinceName,
        }
      }),
    ].filter(upsell =>
      upsell.status === 'completed' &&
      // Users don't need an airport transfers if they're in the same state as the hotel
      (!geoStateName || geoStateName !== upsell.state) &&
      // Make sure we have all the values
      upsell.coords.latitude &&
      upsell.coords.longitude &&
      upsell.from &&
      upsell.to,
    )

    const earliest = min(possibleUpsells, upsell => new Date(upsell.from))
    const latest = max(possibleUpsells, upsell => new Date(upsell.to))

    return [earliest, latest]
  }, [geoStateName, hasTransfersAlready, order.bedbankItems, order.items])

  const [earliest, earliestTransfers] = useExperienceList({
    categoryCodes: airportCategory,
    from: earliestUpsell?.from,
    to: earliestUpsell?.from,
    latitude: earliestUpsell?.coords.latitude,
    longitude: earliestUpsell?.coords.longitude,
  })

  const [latest, latestTransfers] = useExperienceList({
    categoryCodes: airportCategory,
    from: latestUpsell?.to,
    to: latestUpsell?.to,
    latitude: latestUpsell?.coords.latitude,
    longitude: latestUpsell?.coords.longitude,
  })

  const mainUpsell = useMemo(() => {
    const transfers: Array<{ upsell: AirportTransferUpsellData, price: number}> = []
    if (earliestUpsell && earliestTransfers.length > 0) {
      transfers.push({
        upsell: earliestUpsell,
        price: min(earliestTransfers, transfer => transfer.price)!.price,
      })
    }

    if (latestUpsell && latestTransfers.length > 0) {
      transfers.push({
        upsell: latestUpsell,
        price: min(latestTransfers, transfer => transfer.price)!.price,
      })
    }

    return min(nonNullable(transfers), transfer => transfer.price)
  }, [earliestUpsell, earliestTransfers, latestUpsell, latestTransfers])

  const [experienceSearchUrl, fetchingExperienceUrl] = useExperienceSearchUrl({
    latLong: mainUpsell?.upsell.coords,
    placeLevels,
    categoryIds: airportCategory,
  })

  const enabled =
    isValidForTransfers &&
    !dismissed &&
    !hasTransfersAlready &&
    mainUpsell

  const airportTransferUpsell = useMemo<App.AirportTransferUpsell>(() => {
    return {
      price: mainUpsell?.price,
      experienceSearchUrl,
    }
  }, [experienceSearchUrl, mainUpsell?.price])

  const airportTransferDataLoading = !!(fetchingExperienceUrl || earliest.fetching || latest.fetching)

  if (enabled) {
    return {
      airportTransferUpsellEnabled: true,
      airportTransferUpsell,
      handleAirportTransferDismiss,
      airportTransferDataLoading,
    }
  } else {
    return {
      airportTransferUpsellEnabled: false,
      airportTransferUpsell: undefined,
      handleAirportTransferDismiss: undefined,
      airportTransferDataLoading,
    }
  }
}

export default useAirportTransferUpsell
