import React from 'react'
import LinePlaneDepartureIcon from 'components/Luxkit/Icons/line/LinePlaneDepartureIcon'
import OrderUpsellTileWithTracking from 'components/Common/OrderUpsellTileWithTracking'

interface Props {
  flightUpsellData: App.FlightUpsell;
  variant?: 'compact' | 'default';
  onDismiss?: () => void;
}

function FlightUpsellTile(props: Props) {
  const {
    flightUpsellData,
    variant = 'default',
    onDismiss,
  } = props

  const {
    flightTitle,
    flightPrice,
    flightLink,
  } = flightUpsellData

  return <OrderUpsellTileWithTracking
    title={flightTitle}
    description="Explore and compare competitive prices from all major airlines."
    price={flightPrice}
    priceUnit="adult"
    IconComponent={LinePlaneDepartureIcon}
    to={flightLink}
    analyticsSubject="flight"
    variant={variant}
    onDismissClick={onDismiss}
  />
}

export default FlightUpsellTile
