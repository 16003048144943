import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { rem } from 'polished'

import cn from 'clsx'
import LineInfoCircleIcon from 'components/Luxkit/Icons/line/LineInfoCircleIcon'
import HeadingTextBlock from 'components/Luxkit/TextBlocks/HeadingTextBlock'
import Tooltip from 'components/Luxkit/Tooltip'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Group from 'components/utils/Group'
import { checkCanViewLuxPlusBenefits, isEnabledLuxPlusDiscountedInsurance } from 'luxPlus/selectors/featureToggle'
import AirportTransferUpsellTile from './UpsellTiles/AirportTransferUpsellTile'
import CarHireUpsellTile from './UpsellTiles/CarHireUpsellTile'
import FlightUpsellTile from './UpsellTiles/FlightUpsellTile'
import InsuranceUpsellTile from './UpsellTiles/InsuranceUpsellTile'
import TourOptionUpsellTile from './UpsellTiles/TourOptionUpsellTile'
import useAllUpsells from 'hooks/Upsells/useAllUpsells'

const UpsellTileList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${rem(12)};

  ${mediaQueryUp.tablet} {
    grid-template-columns: repeat(auto-fill, minmax(${rem(272)}, 1fr));
  }

  &.lux-plus-sort-order {
    .insurance-upsell-tile {
      order: -1;
    }
  }
`

interface Props {
  order: App.Order;
  className?: string;
}

interface MappedProps {
  canViewLuxPlusBenefits: boolean;
  isEnabledLuxPlusDiscountedInsurance: boolean;
}

function CompactBookingDetailsUpsellSection(props: Props & MappedProps) {
  const {
    order,
    className,
    canViewLuxPlusBenefits,
    isEnabledLuxPlusDiscountedInsurance,
  } = props

  const allUpsells = useAllUpsells(order)

  const {
    tour: { tourUpsellEnabled, tourUpsell, handleTourUpsellDismiss },
    insurance: { insuranceUpsellEnabled, insuranceUpsell, handleInsuranceUpsellDismiss },
    flight: { flightUpsellEnabled, flightUpsell, handleFlightUpsellDismiss },
    airportTransfer: { airportTransferUpsellEnabled, airportTransferUpsell, handleAirportTransferDismiss },
    carHire: { carHireUpsellEnabled, carHireUpsell, handleCarHireUpsellDismiss },
    anyUpsellEnabled,
    anyUpsellLoading,
  } = allUpsells

  const shouldPrioritiseInsurance = canViewLuxPlusBenefits && isEnabledLuxPlusDiscountedInsurance

  if (anyUpsellLoading || !anyUpsellEnabled) return null

  return <Group className={className} gap={16} direction="vertical">
    <Group direction="horizontal" gap={8}>
      <HeadingTextBlock variant="heading5" endIcon={
        <Tooltip
            description="Prices shown are estimates, with the final amount displayed at the next step. Free cancellation policy may change based on your selected dates."
          >
          <LineInfoCircleIcon />
        </Tooltip>
        }>
        What's next to plan?
      </HeadingTextBlock>
    </Group>
    <UpsellTileList className={cn({ 'lux-plus-sort-order': shouldPrioritiseInsurance })}>
      {tourUpsellEnabled && <TourOptionUpsellTile tourUpsellData={tourUpsell} variant="compact" onDismiss={handleTourUpsellDismiss}/>}
      {flightUpsellEnabled && <FlightUpsellTile flightUpsellData={flightUpsell} variant="compact" onDismiss={handleFlightUpsellDismiss} />}
      {insuranceUpsellEnabled && <InsuranceUpsellTile insuranceUpsellData={insuranceUpsell} variant="compact" onDismiss={handleInsuranceUpsellDismiss}/>}
      {airportTransferUpsellEnabled && <AirportTransferUpsellTile transferUpsellData={airportTransferUpsell} variant="compact" onDismiss={handleAirportTransferDismiss}/>}
      {carHireUpsellEnabled && <CarHireUpsellTile carHireUpsellData={carHireUpsell} variant="compact" onDismiss={handleCarHireUpsellDismiss}/>}
    </UpsellTileList>
  </Group>
}

const mapStateToProps = (state: App.State): MappedProps => ({
  canViewLuxPlusBenefits: checkCanViewLuxPlusBenefits(state),
  isEnabledLuxPlusDiscountedInsurance: isEnabledLuxPlusDiscountedInsurance(state),
})

export default connect(mapStateToProps)(CompactBookingDetailsUpsellSection)
