import { useCarHireSearchLink } from 'checkout/Components/Confirmation/BookingDetailsV2/UpsellTiles/useCarHireSearchLink'
import config from 'constants/config'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import useOffers from 'hooks/Offers/useOffers'
import { useAppSelector } from 'hooks/reduxHooks'
import { max, min, nonNullable } from 'lib/array/arrayUtils'
import { isEnabledForFeature } from 'lib/config/featureFlagUtils'
import { isLEHotel } from 'lib/offer/offerTypes'
import { useCallback, useMemo, useState } from 'react'
import {
  get as getLocalStorage,
  set as setLocalStorage,
} from 'lib/storage/isomorphicLocalStorage'
import { getUpsellDismissalStorageKey } from 'lib/order/upsellUtils'
import { CAR_HIRE_MOCKED_TOP_LOCATION_COUNTRIES } from 'constants/config/region'

interface CarHireUpsellData {
  startDate: string;
  endDate: string;
  longitude?: number;
  latitude?: number;
  validCarHireOfferCountry?: boolean;
  status?: App.OrderItemStatus;
}

function useCarHireUpsell(
  order: App.Order,
): {
  carHireUpsellEnabled: true
  carHireUpsell: App.CarHireUpsell
  handleCarHireUpsellDismiss: () => void
  carHireDataLoading: boolean
} | {
  carHireUpsellEnabled: false
  carHireUpsell: undefined
  handleCarHireUpsellDismiss: undefined
  carHireDataLoading: boolean
} {
  const [dismissed, setDismissed] = useState<boolean>(!!getLocalStorage(getUpsellDismissalStorageKey('car-hire', order.id)))

  const handleCarHireUpsellDismiss = useCallback(() => {
    setLocalStorage(getUpsellDismissalStorageKey('car-hire', order.id), true)
    setDismissed(true)
  }, [order.id])

  const offerIds = useMemo(() => [
    ...order.items.map((i) => i.offer.id),
  ], [order.items])

  const [offers, fetching] = useOffers(offerIds)
  const isCarHireEnabled = useAppSelector(state => isEnabledForFeature(config.CARHIRE_REGIONS, state.geo.currentRegionCode))
  const orderIsValidForCarHireUpsell = !order.carHireItems.length && !!(order.items.length || order.bedbankItems.length || order.flightItems.length)

  const [earliestItem, latestItem] = useMemo(() => {
    const possibleUpsells: Array<CarHireUpsellData> = [
      ...nonNullable(order.items.map(item => {
        const hotelOffer = offers.find(offer => offer.id === item.offer.id)
        if (isLEHotel(hotelOffer) && item.offer.property && item.reservation) {
          return {
            startDate: item.reservation.startDate,
            endDate: item.reservation.endDate,
            latitude: hotelOffer.property.latitude,
            longitude: hotelOffer.property.longitude,
            validCarHireOfferCountry: !hotelOffer.property.geoData?.country || CAR_HIRE_MOCKED_TOP_LOCATION_COUNTRIES.includes(hotelOffer.property.geoData?.country),
            status: item.status,
          }
        }
      })),
      ...nonNullable(order.bedbankItems.map(item => {
        return {
          startDate: item.checkIn.format(ISO_DATE_FORMAT),
          endDate: item.checkOut.format(ISO_DATE_FORMAT),
          latitude: item.offer.property.latitude,
          longitude: item.offer.property.longitude,
          validCarHireOfferCountry: CAR_HIRE_MOCKED_TOP_LOCATION_COUNTRIES.includes(item.offer.property.address.countryName),
          status: item.status,
        }
      })),
    ].filter(item => item.status === 'completed')

    const earliest = min(possibleUpsells, item => new Date(item.startDate))
    const latest = max(possibleUpsells, item => new Date(item.endDate))

    return [earliest, latest]
  }, [order.items, order.bedbankItems, offers])

  const [carHireSearchLink, carHireLinkFetching] = useCarHireSearchLink(earliestItem?.startDate, latestItem?.endDate, earliestItem?.latitude, earliestItem?.longitude)

  const enabled =
    !dismissed &&
    orderIsValidForCarHireUpsell &&
    earliestItem?.validCarHireOfferCountry &&
    latestItem?.validCarHireOfferCountry &&
    isCarHireEnabled

  const carHireUpsell = useMemo<App.CarHireUpsell>(() => {
    return {
      carHireSearchLink,
    }
  }, [carHireSearchLink])

  const carHireDataLoading = fetching || carHireLinkFetching

  if (enabled) {
    return {
      carHireUpsellEnabled: true,
      carHireUpsell,
      handleCarHireUpsellDismiss,
      carHireDataLoading,
    }
  } else {
    return {
      carHireUpsellEnabled: false,
      carHireUpsell: undefined,
      handleCarHireUpsellDismiss: undefined,
      carHireDataLoading,
    }
  }
}

export default useCarHireUpsell
