import { fetchInsuranceQuote, FetchQuoteParams } from 'actions/InsuranceActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { getInsuranceQuoteKey } from 'lib/insurance/insuranceUtils'
import { useEffect } from 'react'

interface InsuranceQuoteOptions extends Partial<FetchQuoteParams> {
  disabled?: boolean;
}

type QuoteReturn = [
  quote: App.InsuranceQuote | undefined,
  fetching: boolean,
  error: any,
]

function useInsuranceQuote(
  productId: string | undefined,
  params: InsuranceQuoteOptions,
  options: { disabled?: boolean } = {},
): QuoteReturn {
  const dispatch = useAppDispatch()

  const key = getInsuranceQuoteKey(productId ?? '', params)
  const quote = useAppSelector(state => state.insurance.quotes[key])
  const fetching = useAppSelector(state => !!state.insurance.fetchingQuotes[key])
  const error = useAppSelector(state => state.insurance.fetchQuotesErrors[key])

  useEffect(() => {
    if (
      !options.disabled &&
      productId &&
      // these are the minimum required fields, make sure they are there
      params.startDate &&
      params.endDate &&
      params.coverAmount &&
      params.destinationCountries?.length &&
      params.travellers
    ) {
      dispatch(fetchInsuranceQuote(productId, params as FetchQuoteParams))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, options.disabled])

  return [
    quote,
    fetching ?? !options.disabled,
    error,
  ]
}

export default useInsuranceQuote
