import { getUpsellFlightLink } from 'checkout/Components/Confirmation/BookingDetailsV2/utils'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import useOffers from 'hooks/Offers/useOffers'
import { useAppSelector } from 'hooks/reduxHooks'
import useFlightPrice from 'hooks/useFlightPrice'
import { max, min, nonNullable } from 'lib/array/arrayUtils'
import findClosestAirport from 'lib/flights/findClosestAirport'
import { getKilometersDistanceFromLatLong } from 'lib/flights/getDistanceFromLatLong'
import { countTravellersAsOccupants, countOccupants } from 'lib/offer/occupancyUtils'
import { isLEHotel } from 'lib/offer/offerTypes'
import { getUpsellDismissalStorageKey } from 'lib/order/upsellUtils'
import { useCallback, useMemo, useState } from 'react'
import { getAirportsByCode, getDefaultAirport, isStandaloneFlightsEnabled } from 'selectors/flightsSelectors'
import {
  get as getLocalStorage,
  set as setLocalStorage,
} from 'lib/storage/isomorphicLocalStorage'

interface UpsellFlightData {
  offerId?: string;
  occupants?: App.Occupants;
  destinationAirport?: App.Airport;
  locationHeading?: string;
  startDate: string;
  endDate: string;
  status?: App.OrderItemStatus;
  flightsMaxArrivalTime?: string;
  flightsMinReturningDepartureTime?: string;
  type?: string;
}

const GENERIC_OCCUPANTS: App.Occupants = {
  adults: 1,
  children: 0,
  infants: 0,
}

function useFlightUpsell(
  order: App.Order,
): {
  flightUpsellEnabled: true
  flightUpsell: App.FlightUpsell
  handleFlightUpsellDismiss: () => void
  flightDataLoading: boolean
} | {
  flightUpsellEnabled: false
  flightUpsell: undefined
  handleFlightUpsellDismiss: undefined
  flightDataLoading: boolean
} {
  const [dismissed, setDismissed] = useState<boolean>(!!getLocalStorage(getUpsellDismissalStorageKey('flight', order.id)))

  const handleFlightUpsellDismiss = useCallback(() => {
    setLocalStorage(getUpsellDismissalStorageKey('flight', order.id), true)
    setDismissed(true)
  }, [order.id])

  const originAirport = useAppSelector(getDefaultAirport)
  const offerIds = useMemo(() => [
    ...order.items.map((i) => i.offer.id),
    ...order.tourItems.map((i) => i.tourId),
  ], [order.items, order.tourItems])

  const [offers, offerFetching] = useOffers(offerIds)

  const airportsByCode = useAppSelector(state => getAirportsByCode(state))
  const airports = useAppSelector(state => state.geo.airports)
  const standAloneFlightEnabled = useAppSelector(isStandaloneFlightsEnabled) && !order.hasFlight

  const [earliestItem, latestItem] = useMemo(() => {
    const possibleUpsells: Array<UpsellFlightData> = [
      ...nonNullable(order.items.map(item => {
        const hotelOffer = offers.find(offer => offer.id === item.offer.id)
        if (isLEHotel(hotelOffer) && item.offer.property && item.reservation) {
          return {
            offerId: item.offer.id,
            occupants: {
              adults: item.reservation.adults,
              children: item.reservation.children,
              infants: item.reservation.infants,
            },
            destinationAirport: airportsByCode[hotelOffer.flightDestinationPort],
            locationHeading: item.offer.property.locationHeading,
            flightsMaxArrivalTime: hotelOffer.flightsMaxArrivalTime,
            flightsMinReturningDepartureTime: hotelOffer.flightsMinReturningDepartureTime,
            startDate: item.reservation.startDate,
            endDate: item.reservation.endDate,
            status: item.status,
            type: 'hotel',
          }
        }
      })),
      ...nonNullable(order.tourItems.map(item => {
        const travellers = countTravellersAsOccupants(item.tour.travellers)

        return {
          offerId: item.tourId,
          occupants: {
            adults: travellers.adults,
            children: travellers.children,
            infants: travellers.infants,
          },
          locationHeading: item.tour.startLocation,
          startDate: item.tour.startDate,
          endDate: item.tour.endDate,
          status: item.status,
          type: 'tour',
        }
      })),
      ...nonNullable(order.bedbankItems.map(item => {
        const closestDestinationAirport = findClosestAirport(airports, item.offer.property.latitude, item.offer.property.longitude)
        const occupancies: Array<App.Occupants> = item.rooms.map(room => room.occupancy)
        const occupants = countOccupants(occupancies)

        return {
          offerId: item.offer.id,
          occupants: {
            adults: occupants.adults,
            children: occupants.children,
            infants: occupants.infants,
          },
          destinationAirport: airportsByCode[closestDestinationAirport?.code ?? ''],
          locationHeading: item.offer.property.address.city,
          startDate: item.checkIn.format(ISO_DATE_FORMAT),
          endDate: item.checkOut.format(ISO_DATE_FORMAT),
          status: item.status,
          type: 'bedbank',
        }
      })),
    ].filter(item => item.status === 'completed')

    const earliest = min(possibleUpsells, item => new Date(item.startDate))
    const latest = max(possibleUpsells, item => new Date(item.endDate))

    return [earliest, latest]
  }, [order.items, order.tourItems, order.bedbankItems, offers, airportsByCode, airports])

  const [flightPrice, flightPriceFetching] = useFlightPrice(
    earliestItem?.destinationAirport?.code || '',
    [GENERIC_OCCUPANTS],
    earliestItem?.startDate,
    latestItem?.endDate,
    originAirport?.code,
  )

  const flightLink = useMemo<string>(() => {
    if (!earliestItem || !originAirport) {
      return '/flights'
    }
    return getUpsellFlightLink(
      earliestItem?.startDate,
      latestItem?.endDate,
      (earliestItem?.occupants || GENERIC_OCCUPANTS),
      originAirport,
      earliestItem?.destinationAirport,
      earliestItem?.flightsMaxArrivalTime,
      earliestItem?.flightsMinReturningDepartureTime,
    )
  }, [earliestItem, latestItem, originAirport])

  const distanceBetweenAirports = useMemo<number>(() => {
    if (!originAirport || !earliestItem?.destinationAirport) return 0
    return getKilometersDistanceFromLatLong(
      originAirport.latitude,
      originAirport.longitude,
      earliestItem.destinationAirport.latitude,
      earliestItem.destinationAirport.longitude,
    )
  },
  [originAirport, earliestItem])

  const flightTitle = earliestItem?.destinationAirport ? `Return flights to ${earliestItem.destinationAirport.name}` : 'Add flights'
  const orderIsValidTypeForFlightsUpsell = !!(order.items.length || order.bedbankItems.length || order.tourItems.length)

  const orderIsValidForFlights = orderIsValidTypeForFlightsUpsell && standAloneFlightEnabled
  const isAirportTooCloseToUser = originAirport?.code === earliestItem?.destinationAirport?.code || distanceBetweenAirports < 200
  const isFirstOfferTypeTour = earliestItem?.type === 'tour'

  const enabled =
  !dismissed &&
  (orderIsValidForFlights && (!isAirportTooCloseToUser && !isFirstOfferTypeTour))

  const flightUpsell = useMemo<App.FlightUpsell>(() => {
    return {
      flightPrice,
      flightLink,
      flightTitle,
    }
  }, [flightLink, flightPrice, flightTitle])

  const flightDataLoading = offerFetching || flightPriceFetching
  if (enabled) {
    return {
      flightUpsellEnabled: true,
      flightUpsell,
      handleFlightUpsellDismiss,
      flightDataLoading,
    }
  } else {
    return {
      flightUpsellEnabled: false,
      flightUpsell: undefined,
      handleFlightUpsellDismiss: undefined,
      flightDataLoading,
    }
  }
}

export default useFlightUpsell
